<template>
    <div class="store-login" id="app">
        <!-- 背景  -->
        <div class="login-s">
            <!-- 登录表单 -->
            <el-form :model="loginForm" ref="LoginFormRef" :rules="loginFormRules" label-width="0px" class="login_form">
                <h1 style=" color: white; ">l2qq.com</h1>
                <!-- 用户名 -->
                <el-form-item prop="user_name">
                    <el-input v-model="loginForm.user_name" prefix-icon="el-icon-user"></el-input>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="pass_word">
                    <el-input type="password" v-model="loginForm.pass_word" @keyup.enter.native="login" prefix-icon="el-icon-key"></el-input>
                </el-form-item>
                <!-- 按钮 -->
                <el-form-item class="btns">
                    <el-button type="primary" @click="login">登录</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data: function() {
            return {
                loginForm: {
                    user_name: '',
                    pass_word: ''
               },
                //表单验证规则
                loginFormRules: {
                    user_name: [
                        {required: true, message: "请输入手机号", trigger: "blur"},
                        // 这个只能验证手机号
                        // {pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur"}
                        {pattern:/^((0\d{2,3}-\d{7,8})|(1[135784]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur"}
                    ],
                    pass_word: [{
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur'
                   },
                        {
                            min: 6,
                            max: 15,
                            message: '密码长度在 6 到 15 个字符',
                            trigger: 'blur'
                       }
                    ]
               }
           }
       },
        methods: {
            login() {
                //点击登录的时候先调用validate方法验证表单内容是否有误
                //console.log(this.$refs);
                this.$refs.LoginFormRef.validate(async valid => {
                    //console.log(this.loginFormRules)
                    //如果valid参数为true则验证通过
                    if (!valid) {
                        return
                   }
                    //发送请求进行登录
                    this.$axios({
                        method:'post',
                        url:'/admin/doLogin',
                        params:this.loginForm
                   }).then((response) =>{         //这里使用了ES6的语法
                        //console.log(response)       //请求成功返回的数据
                        if(response.data.state === "ok"){
                            this.$message({
                                showClose: true,
                                message: '登录成功',
                                type: 'success'
                           });
                            //保存token
                            window.sessionStorage.setItem('sessionId', response.data.sessionId)
                            window.sessionStorage.setItem('roleVersion', response.data.loginAccount.roleVersion)
                            //console.log('$_findRoleVersion',response.data);
                            //window.sessionStorage.setItem("runTimeTest", response.data.runTimeTest);
                            //保存用户有哪些权限
                            //window.sessionStorage.setItem('actionKeyList', response.data.actionKeyList)

                            //window.sessionStorage.setItem('loginAccount', response.data.loginAccount)
                            this.$store.dispatch('asyncUpdateRunTimeTest',response.data.runTimeTest);
                            this.$store.dispatch('asyncUpdateLoginAccount',response.data.loginAccount);
                            // 导航至/home
                            this.$router.push('/mainIndex')
                       }else{
                            this.$message({
                                showClose: true,
                                message: response.data.msg,
                                type: 'error'
                           });
                            return
                       }
                   }).catch((error) =>{
                        console.log(error)
                        this.$message({
                            showClose: true,
                            message: '网络超时，请重试！',
                            type: 'error'
                        });
                   });
               })
           }
       },

        created() {

       }
   }
</script>

<style scoped>
    .el-form-item{
        margin-bottom: 22px !important;
   }
    >>> .el-form-item__error{
        color: #ffffff !important;
   }
    .store-login {
        background-color: white;
        width: 100vw;
        height: 100vh;
        opacity: 0.8;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(to right, #4158d0, #ffcc70, #c850c0) !important;
   }


    .login-s {
        position: absolute;
        width: 420px;
        height: 320px;
        background-color: rgba(0, 0, 0, 0.3);
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        z-index: 2;
        box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.7);
        text-align: center;
   }

    .login_form {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 30px;
   }

</style>